[data-section="books"] {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
  
    --muted: 243 5% 96%;
    --muted-foreground: 215.4 16.3% 46.9%;
  
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
  
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
  
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
  
    --primary: 349 89% 60%;
    --primary-foreground: 0 0% 100%;
  
    --secondary: 243 11% 4%;
    --secondary-foreground: 0 0% 98%;
  
    --accent: 243 11% 4%;
    --accent-foreground: 0 0% 100%;
  
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
  
    --ring: 349 89% 60%;
  
    --radius: 0.5rem;
  }
  
  .dark [data-section="books"] {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
  
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
  
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
  
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
  
    --border: 216 34% 17%;
    --input: 216 34% 17%;
  
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
  
    --primary: 349 89% 60%;
    --primary-foreground: 0 0% 100%;
  
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
  
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
  
    --ring: 216 34% 17%;
  
    --radius: 0.5rem;
  }

  